<template>
  <section class="page-wrap">
    <!-- 表单 start -->
    <div class="search">
      <div class="search-item">
        <span>供货日期：</span>
        <el-date-picker
          v-model="formData.supplierTime"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="选择供货日期"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <el-input
          v-model="formData.supplierOrderNo"
          placeholder="输入供货单号"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-input
          v-model="formData.qcNum"
          placeholder="输入复核单号"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-select
          v-model="formData.qcStatus"
          clearable
          placeholder="选择复核状态"
        >
          <el-option label="未复核" value="-1"></el-option>
          <el-option label="已复核" value="1"></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-input
          v-model="formData.productName"
          placeholder="输入品名"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-input
          v-model="formData.productSkuCode"
          placeholder="输入货号"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-select
          v-model="formData.isShort"
          clearable
          placeholder="选择是否缺货"
        >
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="-1"></el-option>
        </el-select>
      </div>

      <div class="search-item">
        <el-button
          class="bjsearch"
          type="primary"
          icon="el-icon-refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="search-item">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
      <div class="search-item">
        <el-button @click="onHandleExport" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
      <div class="search-item">
        <el-button type="warning" @click="onHandlePrint">打印跟车单</el-button>
      </div>
      <div class="search-item" v-if="self_management">
        <el-button type="success" @click="onHandleBatch">打印提货单</el-button>
      </div>
    </div>
    <!-- 表单 end -->
    <!-- table start -->
    <div class="table-wrap">
      <el-table
        v-loading="loading"
        height="100%"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        tooltip-effect="dark"
      >
        <el-table-column type="index" align="center" width="80" label="序号">
        </el-table-column>
        <el-table-column label="整单复核码" prop="supplierNo" align="center">
        </el-table-column>
        <el-table-column label="单品复核码" prop="qcCode" align="center">
        </el-table-column>
        <el-table-column label="供货日期" prop="supplierTime" align="center">
        </el-table-column>
        <el-table-column label="订货数量" prop="originNumber" align="center">
        </el-table-column>
        <el-table-column label="复核数量" prop="reviewNumber" align="center">
        </el-table-column>
        <el-table-column label="是否缺货" prop="stockStatus" align="center">
        </el-table-column>
        <el-table-column label="缺货数量" prop="stockOutNumber" align="center">
        </el-table-column>
        <el-table-column label="自提点" prop="deliverName" align="center">
        </el-table-column>
        <el-table-column label="品名" prop="title" align="center">
        </el-table-column>
        <el-table-column label="品类" prop="categoryName" align="center">
        </el-table-column>
        <el-table-column label="货号" prop="sku_code" align="center">
        </el-table-column>
        <el-table-column label="复核状态" prop="qcStatus" align="center">
        </el-table-column>
        <el-table-column label="复核完成时间" prop="finishTime" align="center">
        </el-table-column>
        <el-table-column label="复核员" prop="qcUserName" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
import { formatDate } from "@/utils/tools/date.js";
import { getTdcCartExport } from "@/api/export/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { BASE } from "@/api/index";
export default {
  name: "ToFollowCart",
  data() {
    return {
      formData: {
        supplierOrderNo: "",
        qcNum: "",
        supplierTime: formatDate(),
        qcStatus: "",
        productName: "",
        productSkuCode: "",
        isShort: "",
        page: 1,
        pageSize: 10,
      },
      self_management: 0,
      userList: [],
      statusList: [],
      orderId3: "",
      orderId4: "",
      dataList: [],
      currentPage: 1,
      total: 20,
      loading: false,
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);

      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      url.supplierTime =
        (invalid.includes(url.supplierTime) && formatDate()) ||
        url.supplierTime;
      /** 历史丢参问题，修复 end */
      this.formData = url;
      this.currentPage = Number(this.formData.page);
      this.formData.pageSize = Number(this.formData.pageSize);
      console.log(this.formData);
    }
    this.onHandleData();
  },
  methods: {
    /**
     * 刷新数据
     */
    onHandleRefresh() {
      try {
        this.formData = {
          supplierOrderNo: "",
          qcNum: "",
          supplierTime: formatDate(),
          qcStatus: "",
          productName: "",
          productSkuCode: "",
          isShort: "",
          page: 1,
          pageSize: 10,
        };
        this.tool.getResult(this.formData, window.location.href);
        this.onHandleData();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 获取数据
     */
    onHandleData() {
      try {
        this.loading = true;
        this.$api.tdc.followlist(this.formData).then((res) => {
          console.log(res, "获取数据");
          this.dataList = res.data.list;
          this.total = res.data.total;
          this.self_management = res.data?.self_management;
          this.loading = false;
        });
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 查询
     */
    onHandleSearch() {
      try {
        this.formData.page = 1;
        this.currentPage = 1;
        this.tool.getResult(this.formData, window.location.href);
        this.onHandleData();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 导出
     */
    async onHandleExport() {
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 99999999,
      });
      try {
        let {
          supplierOrderNo,
          qcNum,
          supplierTime,
          qcStatus,
          productName,
          productSkuCode,
          isShort,
        } = this.formData;
        let params = {
          supplierOrderNo,
          qcNum,
          supplierTime,
          qcStatus,
          productName,
          productSkuCode,
          isShort,
        };
        const res = await getTdcCartExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.closeAll();
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.closeAll();
        this.$message.error("导出失败了");
        console.log("ajax err", err);
      }
    },
    /**
     * 打印装车单
     */
    async onHandlePrint() {
      try {
        window.open(
          `${BASE.PRO4}/qc/follow_car` +
            `?supplierTime=${this.formData.supplierTime}&token=${
              sessionStorage.getItem("token")
                ? sessionStorage.getItem("token")
                : ""
            }`
        );
      } catch (err) {
        console.log("ajax getCartSheetPrint err", err);
      }
    },
    /**
     * 打印提货单
     */
    async onHandleBatch() {
      try {
        window.open(
          `${BASE.PRO4}/logistic/take/order/batch/export` +
            `?date=${this.formData.supplierTime}&token=${
              sessionStorage.getItem("token")
                ? sessionStorage.getItem("token")
                : ""
            }`
        );
      } catch (err) {
        console.log("ajax getCartSheetPrint err", err);
      }
    },
    /**
     * 更新了每页数据
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.tool.getResult(this.formData, window.location.href);
      this.onHandleData();
    },
    /**
     * 更新了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.currentPage = val;
      this.tool.getResult(this.formData, window.location.href);
      this.onHandleData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  padding: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;

  /deep/ .el-table .el-table__body .el-table__cell .cell:empty::after {
    content: "—";
  }

  .search {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background: #fff;
  }

  .bjsearch {
    background: #333333;
    border-color: #333333;
  }

  .checkButton {
    background: #1389ae;
    border-color: #1389ae;
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .search-item {
    .el-input {
      width: 207px;
    }

    .el-select {
      width: 207px;
    }
  }

  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    background: #fff;
  }
}
</style>
<style lang="scss">
// .confirmWrapper {
//   // .el-button--primary {
//   //   background: red !important;
//   // }
// }
</style>
