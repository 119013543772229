var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "page-wrap" }, [
    _c("div", { staticClass: "search" }, [
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("span", [_vm._v("供货日期：")]),
          _c("el-date-picker", {
            attrs: {
              type: "date",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              placeholder: "选择供货日期",
            },
            model: {
              value: _vm.formData.supplierTime,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "supplierTime", $$v)
              },
              expression: "formData.supplierTime",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入供货单号", clearable: "" },
            model: {
              value: _vm.formData.supplierOrderNo,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "supplierOrderNo", $$v)
              },
              expression: "formData.supplierOrderNo",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入复核单号", clearable: "" },
            model: {
              value: _vm.formData.qcNum,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "qcNum", $$v)
              },
              expression: "formData.qcNum",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "选择复核状态" },
              model: {
                value: _vm.formData.qcStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "qcStatus", $$v)
                },
                expression: "formData.qcStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "未复核", value: "-1" } }),
              _c("el-option", { attrs: { label: "已复核", value: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入品名", clearable: "" },
            model: {
              value: _vm.formData.productName,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "productName", $$v)
              },
              expression: "formData.productName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入货号", clearable: "" },
            model: {
              value: _vm.formData.productSkuCode,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "productSkuCode", $$v)
              },
              expression: "formData.productSkuCode",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "选择是否缺货" },
              model: {
                value: _vm.formData.isShort,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "isShort", $$v)
                },
                expression: "formData.isShort",
              },
            },
            [
              _c("el-option", { attrs: { label: "是", value: "1" } }),
              _c("el-option", { attrs: { label: "否", value: "-1" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.onHandleRefresh },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.onHandleSearch },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "warning", icon: "el-icon-upload2" },
              on: { click: _vm.onHandleExport },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.onHandlePrint } },
            [_vm._v("打印跟车单")]
          ),
        ],
        1
      ),
      _vm.self_management
        ? _c(
            "div",
            { staticClass: "search-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.onHandleBatch },
                },
                [_vm._v("打印提货单")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "table-wrap" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              height: "100%",
              border: "",
              data: _vm.dataList,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                align: "center",
                width: "80",
                label: "序号",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "整单复核码",
                prop: "supplierNo",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "单品复核码", prop: "qcCode", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "供货日期",
                prop: "supplierTime",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "订货数量",
                prop: "originNumber",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "复核数量",
                prop: "reviewNumber",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "是否缺货",
                prop: "stockStatus",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "缺货数量",
                prop: "stockOutNumber",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "自提点", prop: "deliverName", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "品名", prop: "title", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "品类", prop: "categoryName", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "货号", prop: "sku_code", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "复核状态", prop: "qcStatus", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "复核完成时间",
                prop: "finishTime",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "复核员", prop: "qcUserName", align: "center" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.formData.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }